<template>
  <v-container>
    <v-expansion-panels class="mt-7">
      <v-expansion-panel v-for="(tab, index) in tabs" :key="index">
        <Tab
          :title="tab.title"
          :element-name="tab.elementName"
          :type-tab="tab.typeTab"
        />
      </v-expansion-panel>
    </v-expansion-panels>
      <v-btn
          style="margin-top: 15px"
          color="primary"
          @click="resetCache()"
      >
          Reset cache
      </v-btn>
  </v-container>
</template>

<script>
import Tab from '../components/settings/Tab';
import axios from "axios";

export default {
  components: { Tab },
  data() {
    return {
      tabs: [
        {
          title: 'Dati Riepilogo',
          elementName: 'dato riepilogativo',
          typeTab: 'resume_data',
        },
        {
          title: 'Store Digitali',
          elementName: 'store digitale',
          typeTab: 'digital_store',
        },
        {
          title: 'Aziende (relative ai clienti)',
          elementName: 'azienda',
          typeTab: 'company',
        },
      ],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.path == '/impostazioni') this.$nextTick(this.routeLoaded);
    },
  },
  mounted() {
    this.routeLoaded();
  },
  methods: {
    routeLoaded() {
      if (document.getElementsByTagName('main').length > 0) {
        document
          .getElementsByTagName('main')[0]
          .classList.add('scrollable', 'scrollbar-grey');
      }
    },
      resetCache() {
          axios
              .delete(process.env.VUE_APP_API_URL + "/cache")
              .then(res => {
                  window.location.reload();
              });
      }
  },
};
</script>

<style></style>
